import { Store } from '@ngxs/store';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SetCurrentOption } from '../optionDetail/state/option-detail.actions';
import { OptionDetailComponentState } from '../optionDetail/state/option-detail.state';
import { EngineBOM } from './state/engine-bom.actions';
import { EngineBOMState } from './state/engine-bom.state';
var SharedInfoService = /** @class */ (function () {
    function SharedInfoService(store) {
        this.store = store;
        this.currentSystem = [];
        this.globalSearchPath = false;
        this.isDifferentESN = false;
        this.notificationSystemView$ = this.store.select(OptionDetailComponentState.getCurrentOption)
            .pipe(debounceTime(100));
        this.notificationEngineGraphicView = new Subject();
        this.notificationEngineGraphicView$ = this.notificationEngineGraphicView.asObservable();
        this.notificationDataplate = new Subject();
        this.notificationDataplate$ = this.notificationDataplate.asObservable();
        this.notificationOptionView = new Subject();
        this.notificationOptionView$ = this.notificationOptionView.asObservable();
        this.notificationSystemDetailView = new Subject();
        this.notificationSystemDetailView$ = this.notificationSystemDetailView.asObservable();
        this.notificationArchiveOptionView = new Subject();
        this.notificationArchiveOptionView$ = this.notificationArchiveOptionView.asObservable();
        this.notificationGasketSetsView = new Subject();
        this.notificationGasketSetsView$ = this.notificationGasketSetsView.asObservable();
        this.notificationKitSetsView = new Subject();
        this.notificationKitSetsView$ = this.notificationKitSetsView.asObservable();
        this.subjectMap = {};
        this.isMenuCollapsed = new BehaviorSubject(true);
    }
    Object.defineProperty(SharedInfoService.prototype, "currentEsnInfo", {
        get: function () {
            return this.store.selectSnapshot(EngineBOMState.getESNInfo);
        },
        set: function (currentEsnInfo) {
            this.store.dispatch(new EngineBOM.SetESNInfo(currentEsnInfo));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharedInfoService.prototype, "currentEsnInfo$", {
        get: function () {
            return this.store.select(EngineBOMState.getESNInfo);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharedInfoService.prototype, "currentDataplate", {
        get: function () {
            return this._currentDataplate;
        },
        set: function (currentDataplate) {
            this._currentDataplate = currentDataplate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharedInfoService.prototype, "currentOptionList", {
        get: function () {
            return this.store.selectSnapshot(EngineBOMState.getCurrentOptionList);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharedInfoService.prototype, "currentEsn", {
        get: function () {
            return this.store.selectSnapshot(EngineBOMState.getCurrentESN);
        },
        set: function (value) {
            this.store.dispatch(new EngineBOM.SetCurrentESN(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SharedInfoService.prototype, "currentOption", {
        get: function () {
            return this.store.selectSnapshot(OptionDetailComponentState.getCurrentOption);
        },
        set: function (currentOption) {
            this.store.dispatch(new SetCurrentOption(currentOption));
        },
        enumerable: true,
        configurable: true
    });
    SharedInfoService.prototype.getValue = function () {
        return this.isMenuCollapsed.asObservable();
    };
    SharedInfoService.prototype.setValue = function (newValue) {
        this.isMenuCollapsed.next(newValue);
    };
    SharedInfoService.prototype.publish = function (key, data) {
        this.getSubject(key).next(data);
    };
    SharedInfoService.prototype.subscribe = function (key, subscriber) {
        var observer = this.getSubject(key).asObservable();
        return subscriber ? observer.pipe(map(subscriber)) : observer;
    };
    SharedInfoService.prototype.getSubject = function (key) {
        this.subjectMap[key] = this.subjectMap[key] || new Subject();
        return this.subjectMap[key];
    };
    SharedInfoService.prototype.optionViewUpdated = function () {
        this.notificationOptionView.next("new");
    };
    SharedInfoService.prototype.dataplateUpdated = function () {
        this.notificationDataplate.next("new");
    };
    SharedInfoService.prototype.systemDetailViewUpdated = function () {
        this.notificationSystemDetailView.next("new");
    };
    SharedInfoService.prototype.engineGraphicUpdated = function () {
        this.notificationEngineGraphicView.next("new");
    };
    SharedInfoService.prototype.archiveOptionViewUpdated = function () {
        this.notificationArchiveOptionView.next("new");
    };
    SharedInfoService.prototype.gasketSetsViewUpdated = function () {
        this.notificationGasketSetsView.next("new");
    };
    SharedInfoService.prototype.kitSetsViewUpdated = function () {
        this.notificationKitSetsView.next("new");
    };
    SharedInfoService.prototype.getEffectiveESNInfo = function () {
        var res = this.currentEsnInfo;
        if (res.esn !== this.currentEsn) {
            if (res.mbom === 'package')
                res = res.ebuSN;
            else if (res.packageAsset)
                res = res.packageSN;
        }
        return res;
    };
    return SharedInfoService;
}());
export { SharedInfoService };
