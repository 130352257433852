var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { throwError, from } from 'rxjs';
import { Injector } from '@angular/core';
import { AppSettings } from '../app.settings';
import { catchError, flatMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DI } from '../state/core';
import { DataPlateService } from '../dataplate/dataplate.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorShared } from '../error/ErrorShared';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
var ɵ0 = function () { return DataPlateService; }, ɵ1 = function () { return HttpClient; }, ɵ2 = function () { return Router; }, ɵ3 = function () { return ErrorShared; }, ɵ4 = function () { return SharedInfoService; };
var PartApiService = /** @class */ (function (_super) {
    __extends(PartApiService, _super);
    function PartApiService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.datePipe = new DatePipe('en-US');
        //this.baseUrl = 'src/app/systemView/bb1775-02.json';
        _this.baseUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/optionDetails?";
        return _this;
    }
    PartApiService.prototype.fetchPartDetails = function (path) {
        return this.http.get(this.getUrl(path)).
            pipe(map(function (response) { return response; })).pipe(catchError(this.handleError));
        //.map((response) => response)
        //.catch(this.handleError);
        // .do((data) => console.log('ALL : ' + JSON.stringify(data)))
    };
    PartApiService.prototype.getKitDetails = function (kit) {
        var _this = this;
        return from(this.dataplateservice.isRestructureFlagEnabled(kit.esnType)).pipe(flatMap(function (flag) {
            if (flag) {
                return _this.fetchComponentDetails(kit);
            }
            var path = 'type=kits_options&optionNo=' + kit.kitNo;
            return _this.fetchPartDetails(path);
        }));
    };
    PartApiService.prototype.getOptionDetails = function (option, isPrecheck, errorCallbak) {
        var _this = this;
        return from(this.dataplateservice.isRestructureFlagEnabled(option.esnType)).pipe(flatMap(function (flag) {
            var res = _this.sharedInfoService.getEffectiveESNInfo();
            if (flag) {
                return _this.fetchComponentDetails(option, res.esn);
            }
            var optionNo = option.optionNo;
            var mbom = res.mbom;
            var filmCard = option.filmCard;
            var specGroup = option.specGroup;
            var optionName = option.optionName;
            var optionNoTrim = (optionNo ? optionNo : "").trim();
            var engineBuildDate = null;
            var enginePlantCode = option.plantCode;
            var isPEOption = option.isPEOption;
            var type = option.optionType ? option.optionType : "";
            var isRussiaBlock = (optionName && (optionName.toUpperCase() === 'BLOCK,LONG' || optionName.toUpperCase() === 'BLOCK,SHORT')) ? true : false;
            var getEngineType = function () {
                switch (mbom) {
                    case "mbom": return "mbom_";
                    case "recon": return "rec_";
                    case "jv": return "jv_";
                    case "package": return "package_";
                    case "npbu": return "npbu_";
                    default: return "";
                }
            };
            if (isPEOption || isRussiaBlock)
                type = "pe_options";
            else if (!type || type === "options") {
                type = getEngineType() + "options";
            }
            try {
                enginePlantCode = enginePlantCode || res.enginePlantCode;
                if (option.buildDate != 'null') {
                    engineBuildDate = option.buildDate || '';
                    engineBuildDate = (_this.datePipe
                        .transform(engineBuildDate.replace(/T?(\d{1,2}\:\d{1,2}(\:\d{1,2}(\.\d{1,3}Z?)?)?)/, ''), 'dd-MMM-yy') || '').toLowerCase();
                }
            }
            catch (error) {
                errorCallbak(error);
            }
            var path = "optionNo=" + optionNoTrim + "&type=" + type.trim();
            if (type !== "pe_options")
                path += "&plantCode=" + (enginePlantCode ? enginePlantCode : "").trim();
            if (optionNoTrim.toUpperCase().startsWith('PE') && (optionName.toUpperCase().indexOf('AFTERTREATME') !== -1) && res.esn) {
                path += "&serialNumber=" + res.esn;
            }
            if (type === "options") {
                var precheck = engineBuildDate ? new Date(engineBuildDate).getFullYear() : 2014;
                if (precheck >= 2015) {
                    if (isPrecheck) {
                        path = path.replace('options', 'mbom_options');
                    }
                    ;
                    if ((enginePlantCode === '0' && isPrecheck) || !isPrecheck) {
                        path += "&effectiveDate=" + (engineBuildDate ? engineBuildDate : "").trim();
                    }
                }
                else {
                    path += "&filmCard=" + (filmCard ? filmCard : "").trim() + "&specGroup=" + (specGroup ? specGroup : "").trim();
                }
            }
            else if (type === "jv_options" || type === 'package_options' || (type === 'mbom_options' && enginePlantCode === '0')) {
                path += "&effectiveDate=" + (engineBuildDate ? engineBuildDate : "").trim();
            }
            return _this.fetchPartDetails(path);
        }));
    };
    PartApiService.prototype.fetchComponentDetails = function (component, esn) {
        var baseUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/v2/componentDetails/" + component.componentType;
        var queryParams = {
            esnType: component.esnType,
            componentHash: component.componentHash
        };
        if (esn) {
            queryParams['esn'] = esn;
        }
        if (component.specGroup) {
            queryParams['specGroup'] = component.specGroup;
        }
        return this.http.get(baseUrl, { params: queryParams }).
            pipe(map(function (response) { return response; })).pipe(catchError(this.handleError));
    };
    PartApiService.prototype.getUrl = function (path) {
        return "" + this.baseUrl + path;
    };
    PartApiService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.message));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    ;
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", DataPlateService)
    ], PartApiService.prototype, "dataplateservice", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", HttpClient)
    ], PartApiService.prototype, "http", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", Router)
    ], PartApiService.prototype, "router", void 0);
    __decorate([
        DI.Inject(ɵ3),
        __metadata("design:type", ErrorShared)
    ], PartApiService.prototype, "error", void 0);
    __decorate([
        DI.Inject(ɵ4),
        __metadata("design:type", SharedInfoService)
    ], PartApiService.prototype, "sharedInfoService", void 0);
    return PartApiService;
}(DI.AutoDependencyInjector));
export { PartApiService };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
